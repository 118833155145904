<template>
  <div class="container">
    <!-- //手机号验证码模块 -->
    <div class="inputBox">
      <van-field
        v-model="phone"
        type="tel"
        placeholder="请输入手机号"
        label="手机号"
      />
      <van-field
        v-model="yanzhengma"
        type="tel"
        placeholder="请输入验证码"
        label="验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            style="color: #6492d7"
            @click="getNumber"
            v-if="!send"
            >获取验证码</van-button
          >
          <van-button
            size="small"
            type="primary"
            style="color: #6492d7"
            v-if="send"
            >{{ second }}秒</van-button
          >
        </template>
      </van-field>
    </div>

    <!-- 
      //登录按钮 -->
    <div class="loginButton" @click="login">登 录</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Number, Login } from "../api/api";
export default {
  data() {
    return {
      phone: "",
      yanzhengma: "",
      send: false,
      second: "",
      signature: "", //登录后获取到的signature
    };
  },

  methods: {
    // 获取验证码
    getNumber() {
      if (this.phone == "") {
        Toast("请输入手机号");
      } else {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          Toast("手机号输入有误");
        } else {
          Number({ phone: this.phone }).then((res) => {
            Toast({
              message: "验证码已发送",
              icon: "success",
            });
            this.send = true;
            this.second = 60;
            var time = setInterval(() => {
              this.second--;
              if (this.second == 0) {
                clearInterval(time);
                this.send = false;
              }
            }, 1000);
          });
        }
      }
    },
    // 登录
    login() {
      if (this.phone == "") {
        Toast("请输入手机号");
        return false;
      } else {
        if (
          !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          Toast("手机号输入有误");
        } else if (this.phone && this.yanzhengma) {
          Login({ code: this.yanzhengma, phone: this.phone })
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                Toast.success({
                  message: "登录成功",
                  icon: "success",
                });
                this.signature = res.data.data.signature;
                  this.$store.commit("login", this.signature);
                localStorage.setItem("signature", res.data.data.signature);
                
                //路由调转
                this.$router.replace({ path: '/historyDetail'});
              
              } else {
                Toast(res.data.msg);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (this.yanzhengma == "") {
        Toast("请输入验证码");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  padding: 30px;
  box-sizing: border-box;
  .inputBox {
    margin-top: 170px;
  }
  .loginButton {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: #8dc6d6;
    color: white;
    border-radius: 40px;
    text-align: center;
    margin-top: 50px;
  }
}
</style>
